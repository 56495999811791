@code-size: 85%;

/** global **/
html { font-size: 16px; }

/* space for the top menu */
html { scroll-padding-top: 3.65rem; }
.ui.fixed.menu + .ui.container { padding-top: 3.65rem; }

/* sticky footer */
body { display: flex; flex-direction: column; }
.ui.forced.menu { border-radius: 0 !important; margin-top: auto; margin-bottom: 0; white-space: pre-wrap; }

/* remove visible outlines on focused menu items */
.ui.pagination.menu a:focus, .ui.navigation.menu a:focus { outline: 0; }

/* compact menus should be flex, too */
.ui > .compact.menu { display: flex; }

/** menu **/
.ui.secondary.menu {
    margin-bottom: 0;
    .item {
        height: 2.5em;
        > a { color: inherit; }
        &:hover { background: rgba(0,0,0,.05); }
        @media screen and (max-width: 599px) {
            > .label { margin-left: 0.25em; }
        }
    }
}

/** form with collapsible details/summary section **/
.ui.form {
    > details {
        clear: both;
        margin: 0 0 1em;

        input:not([type]), input[type="date"], input[type="datetime-local"],
        input[type="email"], input[type="file"], input[type="number"],
        input[type="password"], input[type="search"], input[type="tel"],
        input[type="text"], input[type="time"], input[type="url"],
        textarea {
            box-sizing: border-box;
        }
    }
}

/** documentation **/
.ui.documentation {
    code { background-color: #f3f3f3; padding: .2em .3em; font-size: 90%; line-height: normal; }
}

/** timeline **/
.ui.feed {
    > .event {
        > .label {
            img { width: 2.5em; height: 2.5em; object-fit: cover; }
            /* overlapping profile icons */
            .front { display: block; position: relative; width: 1.875em; height: 1.875em; }
            img + img { .front(); margin: -1.85rem 0 0 0.95rem; }
            img + i { .front(); margin: -2.15rem 0 0 0.95rem; }
            i + img { .front(); margin: -1.05rem 0 0 0.95rem; }
            i + i { .front(); margin: -1.75rem 0 0 0.95rem; }
        }

        > .content {
            /* account for the label */
            width: calc(100% - 3.75em);
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;

            > .meta {
                &:empty { display: none; }
                /* actions (e.g. reply and delete) */
                > form { display: inline; }
            }
            > .detail {
                a {
                    img { cursor: pointer !important; }
                    i.user { font-size: 1.5em; vertical-align: text-bottom; color: rgba(0,0,0,.6); }
                    img + i, i + i {
                        color: lightblue;
                        position: relative;
                        left: -10px;
                        bottom: -10px;
                        width: 5px;
                    }
                }
            }

            > .extra.text {
                max-width: unset;
            }

            > details > summary + * {
                margin: 0.5em 0 0;
            }

            /* tweak margin on figures */
            figure { margin: 1em auto; }

            /* style figcaptions */
            figcaption { text-align: center; font-style: italic; }

            /* other styles */
            code { background: #f3f3f3; padding: .2em .3em; font-size: @code-size; line-height: normal; }
            pre { background: #f6f8fa; padding: .2em .4em; font-size: @code-size; overflow-x: auto; }
        }
    }

    /* threaded posts */
    .threaded.depth-1 { margin-left: 1rem !important; width: calc(100% - 1rem) !important; }
    .threaded.depth-2 { margin-left: 2rem !important; width: calc(100% - 2rem) !important; }
    .threaded.depth-3 { margin-left: 3rem !important; width: calc(100% - 3rem) !important; }
    .threaded.depth-4 { margin-left: 4rem !important; width: calc(100% - 4rem) !important; }
    .threaded.depth-5 { margin-left: 5rem !important; width: calc(100% - 5rem) !important; }
    .threaded.depth-6 { margin-left: 6rem !important; width: calc(100% - 6rem) !important; }
    .threaded.depth-7 { margin-left: 7rem !important; width: calc(100% - 7rem) !important; }
    .threaded.depth-8 { margin-left: 8rem !important; width: calc(100% - 8rem) !important; }
    .threaded.depth-9 { margin-left: 9rem !important; width: calc(100% - 9rem) !important; }

    /* media in posts
       - classes "ui" and "image" are added to every `img` tag during
         HTML sanitization, including tags present in the object
         content. therefore, these classes are reliably on every image
         in a post.
       - media in `.extra.text` are in the object content. this is
         true of both local and remote posts.
       - media in `.extra.media` are "thumbnail size" attachments.
       - media with the class "extra" are "full size" attachments.
    */
    .ui.image { object-fit: cover; cursor: zoom-in; }
    .extra.media img, .extra.media audio, .extra.media video {
        display: inline-block;
        object-fit: cover;
        vertical-align: middle;
        width: 95px;
        height: 95px;
    }
    > .event {
        .extra.text img.image, .extra.text audio.audio .extra.text video.video { width: 100%; }
        img.extra.image, audio.extra.audio, video.extra.video { width: 100%; }
    }
    > .detailed.event {
        .extra.text img.image, .extra.text audio.audio, .extra.text video.video { height: auto; }
        img.extra.image, audio.extra.audio, video.extra.video { height: auto; }
    }
    @media screen and (max-width: 599px) {           /* phones */
        .extra.text img.image, .extra.text video.video { height: 200px; }
        img.extra.image, video.extra.video { height: 200px; }
    }
    @media screen and (min-width: 600px) {           /* tablets portrait & larger */
        .extra.text img.image, .extra.text video.video { height: 375px; }
        img.extra.image, video.extra.video { height: 375px; }
    }
    @media screen and (min-width: 900px) {           /* tablets landscape & larger */
    }
    @media screen and (min-width: 1200px) {          /* desktops & larger */
    }
    @media screen and (min-width: 1800px) {          /* wide desktops & larger */
    }
}

/** thread page **/
.ui.thread.segments {
    margin-top: 1rem !important;
}

/** large actor partial **/
.ui.actor.segments {
    margin-top: 1rem !important; margin-bottom: 1rem;
    > .segment:first-child {
        min-height: 18rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.blocked {
            min-height: auto;
        }
    }
    > .segment img {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        width: 7rem;
        height: 7rem;
        object-fit: cover;
        background-color: #ffffffa0;
        border-radius: 3.5rem;
        border: 3px solid white;
    }
    > .segment i {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        color: rgba(0,0,0,.6);
        line-height: 5rem;
        font-size: 5rem;
    }
    > .segment th {
        border-right: 1px solid #22242626;
        text-align: right;
        font-weight: normal;
        padding-right: 1ex;
        margin-right: 1ex;
    }
    > .segment td {
        text-align: left;
        font-weight: normal;
        padding-left: 1ex;
    }
}

/** small actor partial **/
.ui.actor.card {
    > .content:first-child {
        position: relative;
        min-height: 8rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.blocked {
            min-height: auto;
        }
    }
    > .content:first-child img {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        background-color: #ffffffa0;
        border-radius: 2rem;
        border: 2px solid white;
    }
    > .content:first-child i {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        color: rgba(0,0,0,.6);
        line-height: 3rem;
        font-size: 3rem;
    }
    > .summary.content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    > .summary.content > * {
        display: inline;
    }
}

/* additional styling for text */
.small.text { font-size: 0.75em; }

/* missing styling for forms */
.ui.form { margin-bottom: 1rem; }
.ui.form:last-child { margin-bottom: 0; }
.ui.inline.form { display: inline-block; margin-bottom: 0; }

/* missing styling for button elements (vs input elements) */
/* used in sign out item in the menu */
.ui.input > button { border: 1px solid rgba(34,36,38,.15); }

/* additional button styles */
/* used in posts in the timeline */
.ui.iconic.button { background: transparent none !important; padding: 0; margin: 0; }
.ui.iconic.blue.button { color: #2185d0 !important; }
.ui.iconic.purple.button { color: #a333c8 !important; }

/* used on social actions */
.ui.accept.button { background: #21ba45 !important; color: #fff !important; }
.ui.reject.button { background: #db2828 !important; color: #fff !important; }
/* used on the timeline and elsewhere */
.ui.dangerous.button { color: #ff2733 !important; }
.ui.dangerous.button:hover { background-color: #f41824 !important; color: #fff !important; transition: 0.2s color ease; }
.ui.dangerous.iconic.button { background-color: transparent !important; color: rgba(0,0,0,.6) !important; }
.ui.dangerous.iconic.button:hover { color: #ff2733 !important; transition: 0.2s color ease; }

/* additional styling for modals */
.ui.modal > .header > i { margin-right: 1.0rem; }

/* mini toggle checkbox styling */
.ui.mini.toggle.checkbox {
    line-height: 12px;
    min-height: 1rem;
    input {
        width: 2.05rem; height: 1.0rem;
        &:checked {
            ~ label {
                color: #2185d0 !important;
                &::after { left: 1.15rem; }
            }
        }
    }
    label {
        font-size: 80%; font-weight: bold; color: rgba(0,0,0,.6); padding-left: 2.5rem; min-height: 1.0rem;
        &::before { width: 2.0rem; height: 1.0rem; }
        &::after { width: 1.0rem; height: 1.0rem; }
    }
}

/** trix toolbar **/
trix-toolbar {
    position: sticky; top: 58px; padding: 0 5px;
    .trix-toolbar-container {
        white-space: normal !important; margin-bottom: 1em; display: flex; flex-wrap: wrap; gap: 0.30em 0.25em;
        button { border: none !important; padding: 0.75em !important; }
        button.trix-active { background: rgba(0,0,0,.05) !important; }
    }
}

/** trix editor **/
trix-editor {
    code { background: #f3f3f3; padding: .2em .3em; font-size: @code-size; line-height: normal; }
    pre { background: #f6f8fa; padding: .2em .4em; font-size: @code-size; overflow-x: auto; }

    /* fix style conflicts with semantic ui */
    .attachment__caption-editor { padding: 0 !important; border: none !important; resize: unset !important; height: unset !important; min-height: unset !important; max-height: unset !important; }
    .attachment__caption { text-align: center; }

    figure img { object-fit: cover; width: 100%; height: auto; }
}

@media (scripting: none) {
    trix-editor {
        display: none;
    }
}

@media (scripting: enabled) {
    trix-editor + textarea {
        display: none;
    }
}

/** transitions **/

/* transitional elements */
.transitional {
    animation: fade-in 0.5s;
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* modals */
.ui.dimmer > .ui.modal {
    display: block;
    transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(0.85);
    opacity: 0.0;
}

.ui.dimmer > .ui.modal.active {
    transform: scale(1.0);
    opacity: 1.0;
}

/** filter terms **/
.ui.filter.term > .wildcard {
    background-color: #eee;
    padding: 0 0.2em;
    margin: 0 0.1em;
}

/** colors **/
.blue { color: #1678c2; }

/** from mastodon **/
.invisible {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    width: 0;
    height: 0;
}

.truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.ellipsis::after {
    content: "…";
}
